@import "../../theme/variables";

.page {
  &.page_auth {
    position: relative;
    @include flexLayout(center, center);
    height: 100vh;
    width: 100%;
    padding: 0;
  }

  &__login-section {
    width: 50%;
    height: 100%;
    @include flexLayout(center, center, column);

    .login-content {
      width: 350px;
      margin: auto;
      text-align: left;

      img {
        width: 120px;
        height: 120px;
      }

      .login-title {
        padding: 24px 0;

        .pre-title {
          font-size: 16px;
          margin: 0;
          color: $color-main;
          opacity: 0.8;
        }

        h3 {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2;
          color: $color-main;
          margin: 0;
        }
      }

      .no-account-link {
        justify-self: self-end;
      }
    }

    .no-account-link {
      margin-bottom: 60px;
      color: #616161; //TODO: set scss variable?
    }
  }

  &__bg-section {
    width: 50%;
    height: 100%;
    background-image: url("../../assets/images/login-shape.jpg");
    background-size: cover;
  }
}

.company-data {
  @include flexLayout(center, space-between);
  color: $color-main;
  gap: 90px;

  .company-info {
    width: calc(100% - 90px - 240px);
  }
}

.company-profile-title {
  font-size: 18px;
  text-transform: uppercase;
  color: $color-dark;
  margin-top: 64px;
}

.company-profile {
  @include flexLayout(center, center, column);
  gap: 32px;
  width: 100%;
  margin-top: 32px;

  &__section {
    @include flexLayout(flex-start, flex-start);
    padding: 24px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 24px rgba(66, 66, 66, 0.08);
    border-radius: 4px;
  }
}
