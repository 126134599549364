.updated {
  animation: updated-keyframes 2.5s;
}

@keyframes updated-keyframes {
  from {
    opacity: 0.01;
    background: rgba(108, 216, 0, 0.503);
  }

  to {
    opacity: 1;
  }
}
