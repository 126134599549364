@import "../../../theme/variables";

.auth-wrapper {
  position: relative;

  button {
    width: 100%;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat";
  }

  .auth-error {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    position: absolute;
    transform: translateY(36px);
  }
}
