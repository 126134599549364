@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./theme/fonts";
@import "./theme/variables";

@layer base {
  :root {
    --font-sans: "Geist", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 243.4 0.75 59%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;
  }
}

body {
  font-family: "Geist", sans-serif;
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
footer,
header,
section {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $color-info;
}

.text {
  &-xs {
    font-size: 12px;
  }

  &-md {
    font-size: 18px;
  }

  &-grey {
    color: $color-secondary;
  }

  &-dark {
    color: $color-dark !important;
  }

  &-info {
    color: $color-info;
  }

  &-bold {
    font-weight: 600;
  }
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.company-logo {
  @include flexLayout(center, center);
  max-height: 80px;
  width: 240px;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;

  // img { max-width: 240px; }
  img {
    width: 240px;
  }
}

.Select-menu {
  position: fixed !important;
}

.flex-space-between {
  width: 100%;
  @include flexLayout(center, space-between);
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-unshrink {
  flex-shrink: 0;
}

.gap-xs {
  gap: 12px;
}

.badge {
  background-color: rgba(71, 71, 71, 0.1);
  font-size: 12px;
  padding: 6px 16px;
  font-weight: bold;
  border-radius: 100px;
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background-color: #1677ff !important;
}

.button-icon {
  background-color: transparent;
  padding: 0;
  border: none;
  color: $color-info;
  gap: 4px;
  line-height: 1;

  img {
    height: 16px;
  }
}

.container {
  padding: 0 130px;
  max-width: 1440px;
  margin: 0 auto;
}

.page {
  padding: 64px 0;

  &__title {
    margin: 0 0 24px 0;
    color: $color-main;
    font-size: 32px;
    font-weight: bold;
  }

  &__header {
    margin-bottom: 56px;
  }

  &__footer {
    @include flexLayout(center, center);
    gap: 24px;
    padding: 12px 24px;
    margin-top: 60px;
    color: $color-main;
    font-size: 20px;
    font-weight: 700;
    background-color: white;
    box-shadow: 0px -4px 31px rgba(0, 0, 0, 0.1);

    .button {
      font-size: 18px;
    }
  }
}

.form-section-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
}

.form-control {
  position: relative;
  width: fit-content;
  @include flexLayout(flex-start, flex-start, column);
  margin-bottom: 32px;
  gap: 8px;

  label {
    color: $color-secondary;
    text-transform: uppercase;
  }

  &__description {
    color: $color-secondary;
    margin: 0;
    font-size: 12px;
  }

  input,
  textarea {
    padding: 12px 24px;
    border-radius: 4px;
    width: 450px;
    max-width: 100%;
    color: $color-main;
    border: 1px solid $color-secondary;
    font-size: 16px;

    &::placeholder {
      color: rgba($color-main, 0.3);
    }
  }

  .label-link-button,
  .input-link-button {
    position: absolute;
    @include flexLayout(center, center);
    color: $color-info;
    font-weight: 600;
    background-color: transparent;
    border: none;
    right: 0;
  }

  .label-link-button {
    gap: 6px;
    padding: 0;
    top: 0;
  }

  .input-link-button {
    bottom: 0;
    padding: 12px 26px;
    height: 100%;
  }

  &.invalid {
    label {
      color: $color-error;
    }

    input,
    textarea {
      border-color: $color-error;
    }

    small {
      color: $color-error;
      position: absolute;
      bottom: 0;
      transform: translateY(calc(100% + 8px));
    }
  }
}

.icon-white {
  filter: invert(100%) contrast(3);
}

.logo-image-container {
  position: relative;
  @include flexLayout(center, center, column);
  overflow: hidden;
  gap: 8px;
  height: 80px;
  width: 240px;

  .button-icon {
    font-size: 16px;
    font-weight: 600;
    color: $color-info;
    text-transform: uppercase;
  }

  img {
    // height: 100%;
    // width: auto;
    width: 240px;
  }
}

.modal-dialog {
  position: relative;

  &__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    @include flexLayout(center, center);
    background-color: transparent;
    color: $color-main;
    border-radius: 50%;
    line-height: 1;
    padding: 0;
    font-size: 18px;
    width: 28px;
    height: 28px;
    border: 2px solid $color-main;
  }
}

.rsm-z-40 {
  z-index: 200 !important;
}


//.redesign .ant-table-row {
//  background-color: white !important;
//}

.redesign .ant-table-cell-row-hover {
  background-color: white !important;
}

.redesign .ant-table-thead .ant-table-cell {
  background: #F8FAFC !important;
}


//.redesign .ant-table-cell.ant-table-cell-fix-left {
//  background-color: white !important;
//}

//.redesign .ant-table-thead .ant-table-cell {
//  border-top: 1px solid #E2E8F0 !important;
//  border-right: 1px solid #E2E8F0 !important;
//  background: #F8FAFC !important;
//  color: #0F172A !important;
//  padding: 16px;
//  font-size: 14px;
//  font-style: normal;
//  font-weight: 700;
//
//  &:first-child {
//    border-left: 1px solid #E2E8F0 !important;
//  }
//}

//.redesign {
//  :where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
//    border-inline-start: none !important;
//    border-top: none !important;
//  }
//
//  .ant-table-body {
//    border: 1px solid #E2E8F0 !important;
//    border-top: none !important;
//  }
//}

.ant-switch {
  background-color: gray;
}

//.tab {
//  .tab-icon {
//    opacity: 0;
//  }
//
//  &:hover {
//    .tab-icon {
//      opacity: 1;
//    }
//  }
//}

.ant-table-cell {
  overflow: hidden;
}

//.ant-table-wrapper .ant-table-tbody > tr > td {
//  padding: 5px;
//  vertical-align: top;
//}

.ant-table-selection-column {
  border-right: none !important;
}

.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}

.ant-drawer-close svg {
  color: #FFFFFF;
}

.redesign .ant-upload-drag .ant-upload {
  padding: 0 !important;
}

//.redesign .last-col {
//  border-inline-end: none !important;
//}

.postProcessingItems {
  .ant-select-status-error {
    .ant-select-selector {
      border-color: #d9d9d9 !important;
    }
  }

  .ant-input-status-error {
    border-color: #d9d9d9!important;
  }
}

.ev-ant {
  .ant-select-selection-placeholder {
    color: rgb(60, 60, 60) !important;
  }
}
