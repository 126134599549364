
// Geist
// @font-face {
//     font-family: "Geist";
//     src: url("../assets/fonts/geist/Geist-ExtraLight.ttf") format("truetype");
//     font-weight: 200;
//     font-style: normal;
// }
  
@font-face {
  font-family: "Geist";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/geist/Geist-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Geist";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/geist/Geist-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Geist";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/geist/Geist-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Geist";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/geist/Geist-Bold.woff2") format("woff2");
}