.wrap {
  position: relative;

  .textArea {
    border: 1px solid transparent !important;

    &:focus {
      border: 1px solid #1677ff !important;
    }

    &:hover {
      border: 1px solid rgb(96, 96, 96) !important;
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.disabled {
  opacity: 0.1;
  pointer-events: none;
}
